<template>
  <div class="home_top">
    <div class="inner">
      <div class="left_item">
        <h3><br>{{ $t('homeTop.h3Title1') }}</h3>
        <p>{{ $t('homeTop.desc') }}</p>
        <!-- <div>
          <a href="/about_us">
            <img src="@/assets/images/loanImg/about_us.png" alt="">
          </a>
          <a href="/contact_us">
            <img src="@/assets/images/loanImg/contact-us.png" alt="">
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

  .home_top{
    min-width: 1200px;
    height: 695px;
    position: relative;
    background: url("~@/assets/images/loanImg/top_bg.png") no-repeat top right;
    .inner{
      width: 1200px;
      margin: 0 auto;
      padding-top: 74px;
      .left_item{
        float: left;
        h3{
          font-size: 36px;
          color: #333333;
          font-weight: bold;
          line-height: 40px;
          margin-bottom: 43px;
        }
        p{
          font-size: 16px;
          color: #666666;
          margin-bottom: 98px;
        }
        div{
          img{
            width: 202px;
            height: 59px;
            margin-right: 20px;
          }
        }
      }
    }
  }

</style>
